import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  AuthActionTypes,
  AuthGetterTypes,
} from "./store/modules/auth/constants";

const init = async () => {
  if (store.getters[`auth/${AuthGetterTypes.IS_AUTHENTICATED}`] == false) {
    await store
      .dispatch(`auth/${AuthActionTypes.AUTO_LOGIN}`)
      .catch(async () => {
        await store.dispatch(`auth/${AuthActionTypes.LOGOUT}`);
      });
  }
  createApp(App).use(store).use(router).mount("#app");
};

init();
