import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { AuthGetterTypes } from "@/store/modules/auth/constants";
import store from "@/store";
import Root from "@/views/Root.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    meta: { requiresAuth: false, title: "Login" },
  },
  {
    path: "/",
    component: Root,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
        meta: { requiresAuth: true, title: "Dashboard" },
        alias: "",
      },
      {
        path: "sessions/:sessionId",
        name: "Session",
        component: () =>
          import(
            /* webpackChunkName: "clinical-eval-session" */ "@/views/Session.vue"
          ),
        meta: { requiresAuth: true, title: "Session" },
        props: true,
      },
    ],
  },
  {
    path: "/500error",
    name: "InternalServerErrorPage",
    component: () => import(/* webpackChunkName: "500" */ "@/views/500.vue"),
    meta: { title: "500 Internal Server Error" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFoundPage",
    component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
    meta: { title: "404 Not Found" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (
    to.meta.requiresAuth &&
    store.getters[`auth/${AuthGetterTypes.IS_AUTHENTICATED}`] == false
  )
    next({ name: "Login" });
  else if (store.getters[`auth/${AuthGetterTypes.IS_AUTHENTICATED}`] == true) {
    switch (to.name) {
      case "Login":
        next({ name: "Dashboard" });
        break;
      default:
        next();
        break;
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title =
      to.meta.title + " | Origin Health Clinical Evaluation Platform";
  }
});
export default router;
