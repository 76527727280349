export const VERSION = "v1";
export const AUTHENTICATIONS = "authentications";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const AUTHENTICATE_TOKEN = "authenticate-token";
export const CURRENT_USER = "current-user";
export const USER = "users";
export const DATA = "data";
export const SESSION = "sessions";
export const COUNT = "count";
