<template>
  <nav class="navbar navbar-dark fixed-top">
    <div class="container-fluid" style="width: 95%">
      <a class="navbar-brand" href="/dashboard">
        <img
          src="../assets/OHA-logo.png"
          alt=""
          height="50"
          class="d-inline-block align-top"
      /></a>
      <a class="btn btn-outline-dark" @click="logout()">Logout</a>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AuthActionTypes } from "@/store/modules/auth/constants";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "NavBar",
  setup() {
    const store = useStore();
    const router = useRouter();

    const logout = () => {
      store.dispatch(`auth/${AuthActionTypes.LOGOUT}`).finally(() => {
        router.replace({ name: "Login" });
      });
    };
    return {
      logout,
    };
  },
});
</script>

<style scoped>
.navbar {
  background-color: #e7e7e7;
}
</style>
