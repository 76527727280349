import { PartialDeep } from "type-fest";
export default class User {
  id = "";
  firstName = "";
  lastName = "";
  username = "";
  userType = "";

  constructor(data?: PartialDeep<User>) {
    if (data) this.setData(data);
  }

  setData(data: PartialDeep<User>): void {
    this.id = data.id ?? this.id;
    this.firstName = data.firstName ?? this.firstName;
    this.lastName = data.lastName ?? this.lastName;
    this.username = data.username ?? this.username;
    this.userType = data.userType ?? this.userType;
  }
}
