import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  AuthActionTypes,
  AuthGetterTypes,
  AuthMutationTypes,
} from "./constants";
import { AuthState, AuthGetters, AuthMutations, AuthActions } from "./typings";
import { RootState } from "@/store/root/typings";
import { AuthService } from "@/services/auth.service";
import User from "@/models/User";

const getDefaultState = (): AuthState => {
  return {
    isAuthenticated: false,
    user: new User({}),
  };
};

const state = getDefaultState();

const getters: GetterTree<AuthState, RootState> & AuthGetters = {
  [AuthGetterTypes.IS_AUTHENTICATED]: (state) => {
    return state.isAuthenticated;
  },

  [AuthGetterTypes.USER]: (state) => {
    return state.user;
  },
};

const mutations: MutationTree<AuthState> & AuthMutations = {
  [AuthMutationTypes.SET_AUTH]: (state, payload) => {
    state.isAuthenticated = payload.isAuthenticated;
    state.user = payload.user;
  },
  [AuthMutationTypes.CLEAR_AUTH]: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions: ActionTree<AuthState, RootState> & AuthActions = {
  async [AuthActionTypes.AUTO_LOGIN]({ commit }) {
    try {
      const authenticateResponse = await AuthService.authenticateToken();
      if (authenticateResponse.status === 200) {
        commit(AuthMutationTypes.SET_AUTH, {
          isAuthenticated: true,
          user: state.user,
        });
        const userResponse = await AuthService.getCurrentUser();
        if (userResponse.status === 200) {
          commit(AuthMutationTypes.SET_AUTH, {
            isAuthenticated: state.isAuthenticated,
            user: userResponse.data.data,
          });
        }
      }
    } catch (err) {
      const errorMessage = err.response.data.msg ?? err.response.data.message;
      throw errorMessage;
    }
  },

  async [AuthActionTypes.LOGIN]({ commit }, payload) {
    try {
      const response = await AuthService.login(payload);
      if (response.status === 200) {
        commit(AuthMutationTypes.SET_AUTH, {
          isAuthenticated: true,
          user: response.data.data,
        });
      }
    } catch (err) {
      const errorMessage = err.response.data.message;
      throw errorMessage;
    }
  },
  async [AuthActionTypes.LOGOUT]({ commit }) {
    try {
      await AuthService.logout();
    } catch {
      return true;
    } finally {
      commit(AuthMutationTypes.CLEAR_AUTH);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
} as Module<AuthState, RootState>;
