import {
  AUTHENTICATE_TOKEN,
  AUTHENTICATIONS,
  CURRENT_USER,
  LOGIN,
  LOGOUT,
} from "@/common/constants/api";
import User from "@/models/User";
import { AxiosResponse } from "axios";
import { BaseService } from "./base.service";
import { HttpRequest } from "./http.init";

export class AuthService {
  /**
   ******************************
   * @API
   ******************************
   */

  static async login(body: {
    username: string;
    password: string;
  }): Promise<AxiosResponse> {
    const response = await new HttpRequest({ auth: false }).instance
      .post(`${AUTHENTICATIONS}/${LOGIN}`, body)
      .catch((err) => {
        throw err;
      });
    response.data = BaseService._camelizeKeys(response.data);
    response.data.data = new User(response.data.data);
    return response;
  }

  static async logout(): Promise<AxiosResponse> {
    const response = await new HttpRequest({ auth: true }).instance
      .post(`${AUTHENTICATIONS}/${LOGOUT}`)
      .catch((err) => {
        throw err;
      });
    response.data = BaseService._camelizeKeys(response.data);
    return response;
  }

  static async authenticateToken(): Promise<AxiosResponse> {
    const response = await new HttpRequest({ auth: true }).instance
      .post(`${AUTHENTICATIONS}/${AUTHENTICATE_TOKEN}`)
      .catch((err) => {
        throw err;
      });
    response.data = BaseService._camelizeKeys(response.data);
    return response;
  }

  static async getCurrentUser(): Promise<AxiosResponse> {
    const response = await new HttpRequest({ auth: true }).instance
      .get(`${AUTHENTICATIONS}/${CURRENT_USER}`)
      .catch((err) => {
        throw err;
      });
    response.data = BaseService._camelizeKeys(response.data);
    response.data.data = new User(response.data.data);
    return response;
  }
}
