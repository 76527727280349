
import { defineComponent } from "vue";
import { AuthActionTypes } from "@/store/modules/auth/constants";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "NavBar",
  setup() {
    const store = useStore();
    const router = useRouter();

    const logout = () => {
      store.dispatch(`auth/${AuthActionTypes.LOGOUT}`).finally(() => {
        router.replace({ name: "Login" });
      });
    };
    return {
      logout,
    };
  },
});
