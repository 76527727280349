export enum AuthActionTypes {
  AUTO_LOGIN = "[actions] Authenticate token and get current user",
  LOGIN = "[actions] Login",
  LOGOUT = "[actions] Logout",
}
export enum AuthMutationTypes {
  SET_AUTH = "[mutations] Set authentication after logging in",
  CLEAR_AUTH = "[mutations] Clear auth after successfully logging out",
}
export enum AuthGetterTypes {
  IS_AUTHENTICATED = "[getters] Authenticated or not",
  USER = "[getters] Current user",
}
