/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { HttpRequest } from "./http.init";
import _ from "lodash";

export class BaseService {
  static get entity(): string {
    throw new Error("entity getter not defined");
  }

  static _request(status = { auth: false }): HttpRequest {
    return new HttpRequest(status);
  }

  static _camelizeKeys(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map((v) => this._camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [_.camelCase(key)]: this._camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  }

  static _snakeCasingKeys(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map((v) => this._snakeCasingKeys(v));
    } else if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [_.snakeCase(key)]: this._snakeCasingKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  }
}
