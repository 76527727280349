
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "App",
  setup() {
    const store = useStore();

    return {
      auth: computed(() => store.state.auth.token),
    };
  },
});
