import {
  AUTHENTICATE_TOKEN,
  AUTHENTICATIONS,
  VERSION,
} from "@/common/constants/api";
import axios, { AxiosInstance } from "axios";
import router from "@/router";
import store from "@/store";
import { AuthActionTypes } from "@/store/modules/auth/constants";

export class HttpRequest {
  private _isAuth: boolean;
  private _instance: AxiosInstance;

  constructor(status: { auth: boolean }) {
    this._isAuth = status.auth;
    this._instance = axios.create({
      withCredentials: true,
      baseURL: `${process.env.VUE_APP_URL}/${VERSION}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (this._isAuth) {
      this._instance.interceptors.request.use((config) => {
        config.headers["X-CSRF-TOKEN"] = this._getCookie("csrf_access_token");
        return config;
      });
    }
    this._instance.interceptors.response.use(undefined, async (error) => {
      const to = {
        name: "",
      };
      switch (error.response.status) {
        case 401:
          if (
            error.response.request.responseURL !=
            `${process.env.VUE_APP_URL}/${VERSION}/${AUTHENTICATIONS}/${AUTHENTICATE_TOKEN}`
          ) {
            await store.dispatch(`auth/${AuthActionTypes.LOGOUT}`);
            to.name = "Login";
          }
      }
      switch (error.response.status) {
        case 404:
          to.name = "NotFoundPage";
          break;
      }
      switch (error.response.status) {
        case 500:
          to.name = "InternalServerErrorPage";
          break;
      }
      if (to.name) router.push(to);
      return Promise.reject(error);
    });
  }

  private _getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
  }

  get instance(): AxiosInstance {
    return this._instance;
  }
}
