<template>
  <div id="app" class="d-flex flex-column">
    <main>
      <NavBar />
      <router-view class="router-view" />
    </main>
  </div>
</template>

<script lang="ts">
import NavBar from "@/components/NavBar.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { NavBar },
});
</script>

<style scoped>
.router-view {
  padding-top: 130px;
}
</style>
